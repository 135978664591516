import axios from 'axios'
import React, { useState } from 'react'
import tw from "twin.macro";
import { css } from "styled-components/macro"; //eslint-disable-line
import { ReactComponent as SvgDecoratorBlob1 } from "images/svg-decorator-blob-9.svg";
import { ContentWithPaddingXl, Container } from "components/misc/Layouts";
const Content = tw.div`max-w-screen-xl mx-auto py-20 lg:py-24`
const PrimaryBackgroundContainer = tw.div`py-16 lg:py-20 bg-purple-200 rounded-lg relative`
const Row = tw.div`px-4 sm:px-16 mx-auto flex justify-center items-center relative z-10 flex-col lg:flex-row text-center lg:text-left`;

const ColumnContainer = tw.div`lg:w-1/2 max-w-lg`
const TextContainer = tw(ColumnContainer)`text-2xl sm:text-4xl font-bold`;
const Subheading = tw.h6`text-primary-500 opacity-75`;
const Heading = tw.h5`text-primary-500`;

const LinksContainer = tw(ColumnContainer)`flex justify-center lg:justify-end mt-6 lg:mt-0 flex-col sm:flex-row`;

const Link = tw.a`w-full sm:w-auto text-sm sm:text-base px-6 py-3 sm:px-8 sm:py-4 lg:px-10 lg:py-5 mt-4 first:mt-0 sm:mt-0 sm:mr-8 sm:last:mr-0 rounded font-bold border border-transparent tracking-wide transition duration-300 focus:outline-none focus:shadow-outline`;
const PrimaryLink = tw(Link)`shadow text-gray-100 hocus:text-gray-300 bg-primary-500 hocus:bg-primary-700`;

const SecondaryLink = tw(Link)`text-primary-500 hover:text-primary-600 bg-gray-100 hover:bg-gray-200`;
const Input = tw.input`w-full sm:w-auto block sm:inline-block px-6 py-4 rounded bg-secondary-600 tracking-wider font-bold border border-secondary-600 focus:border-secondary-300 focus:outline-none sm:rounded-r-none hover:bg-secondary-500 transition duration-300 text-gray-200`

const DecoratorBlobContainer = tw.div`absolute inset-0 overflow-hidden rounded-lg`
const DecoratorBlob1 = tw(SvgDecoratorBlob1)`absolute bottom-0 left-0 w-80 h-80 transform -translate-x-20 translate-y-32 text-primary-500 opacity-5`
const DecoratorBlob2 = tw(SvgDecoratorBlob1)`absolute top-0 right-0 w-80 h-80 transform  translate-x-20 -translate-y-64 text-primary-500 opacity-5`

export default () => {
  const [name, setName] = useState('')
  const [success, setSuccess] = useState('')
  const [error, setError] = useState('')
  const [email, setEmail] = useState('')
  const [phone, setPhone] = useState('')
  const [descriptionForm, setDescriptionForm] = useState('')
  const [loading, setLoading] = useState(false)

  const handleSubmit = (e) => {
    e.preventDefault()
    const payload = {
      name,
      email,
      phone,
      description: descriptionForm,
    }
    setLoading(true)
    axios
      .post(`${process.env.REACT_APP_BACKEND}/sendEmail`, payload)
      .then((res) => {
        setSuccess('Request Recevied Our Executive will contact you shortly')
        setEmail('')
        setDescriptionForm('')
        setName('')
        setPhone('')
        setLoading(false)
        setTimeout(() => {
          setSuccess('')
        }, 6000)
        // document.body.scrollTop = document.documentElement.scrollTop = 0
        setTimeout(() => {
          window.scrollTo({ top: 0, behavior: 'smooth' })
        }, 2000)
      })
      .catch((error) => {
        console.log(error)
        setError('Something Happend Please Try again later')
        setTimeout(() => {
          setError('')
        }, 6000)
        setLoading(false)
      })
  }
  return (
    <Container>
      <Content>
      <Heading>{"Contact Us"}</Heading>
        <Row>
          {error && (
            <p
              className='error'
              style={{
                background: '#fff0f0',
                color: '#ff0000',
                padding: '10px',
              }}
            >
              {error}
            </p>
          )}

          {success && (
            <p
              className='success'
              style={{
                background: '#e7f7e2',
                color: '#0cf327',
                padding: '10px',
              }}
            >
              {success}
            </p>
          )}
          <br />
          <form onSubmit={(e) => handleSubmit(e)}>
            <Input
              type='text'
              onChange={(e) => setName(e.target.value)}
              name='name'
              placeholder='Your Name'
              value={name}
            />{' '}
            <br />
            <br />
            <Input
              type='email'
              name='email'
              onChange={(e) => setEmail(e.target.value)}
              placeholder='Your Email Address'
              value={email}
            />{' '}
            <br />
            <br />
            <Input
              type='Number'
              name='phone'
              value={phone}
              onChange={(e) => setPhone(e.target.value)}
              placeholder='Your Mobile Number'
            />
            <br />
            <br />
            <Input
              type='text'
              onChange={(e) => setDescriptionForm(e.target.value)}
              value={descriptionForm}
              name='description'
              placeholder='description'
            />
            <br />
            <br />
            <button
              style={{
                backgroundColor: '#4CAF50',
                border: 'none',
                color: 'white',
                padding: '15px 32px',
                textAlign: 'center',
                textDecoration: 'none',
                display: 'inline-block',
                fontSize: '16px',
              }}
              type='submit'
              disabled={
                !name || !email || !phone || !descriptionForm || loading
              }
            >
              Contact us
            </button>
          </form>
        </Row>
      </Content>
    </Container>
  )
}


// export default ({
//   subheading = "contact Us page should come here",
//   heading = "",
//   primaryLinkText = "Get Started",
//   primaryLinkUrl = "http://timerse.com",
//   secondaryLinkText = "Contact Us",
//   secondaryLinkUrl = "http://google.com",
//   pushDownFooter = true
// }) => {
//   return (
//     <Container css={pushDownFooter && tw`mb-20 lg:mb-24`}>
//       <ContentWithPaddingXl>
//       <PrimaryBackgroundContainer>
//         <Row>
//           <TextContainer>
//             {subheading && <Subheading>{subheading}</Subheading>}
//             <Heading>{heading}</Heading>
//           </TextContainer>
//           <LinksContainer>
//             <PrimaryLink href={primaryLinkUrl}>{primaryLinkText}</PrimaryLink>
//             <SecondaryLink href={secondaryLinkUrl}>{secondaryLinkText}</SecondaryLink>
//           </LinksContainer>
//         </Row>
//         <DecoratorBlobContainer>
//           <DecoratorBlob1/>
//           <DecoratorBlob2/>
//         </DecoratorBlobContainer>
//       </PrimaryBackgroundContainer>
//       </ContentWithPaddingXl>
//     </Container>
//   );
// };